import { Auth } from 'aws-amplify'
import { API } from 'aws-amplify'
import * as queries from '@/src/graphql/queries'

/* 
TODO: Refactor this middleware and Login logic
- prevent running every time a route changes
*/

export default defineNuxtRouteMiddleware(async (to, from, next) => {
  const authStore = useAuthStore()
  const { user } = authStore
  if (user) {
    return
  }
  try {
    const reportGroups = ['Client', 'Stakeholder']
    if (!reportGroups.includes(db_user.group)) {
      return navigateTo('/')
    }
    return // allow access to reports
  } catch (error) {
    if (to.path === '/login') {
      return
    } else {
      return navigateTo('/')
    }
  }
})
